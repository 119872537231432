<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Leave Requests</h1>
              </div>
              <div class="row">
                <div class="col-md-8 d-flex justify-start">
                  <div class="row">
                    <div class="col-md-4">
                      <TextField type="date" :label="'From'" />
                    </div>
                    <div class="col-md-4">
                     <TextField type="date" :label="'to'" />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 d-flex justify-end">
                  <Button :btnType="'Submit'" :label="'Export'" @onClick="addNew" class="mr-2"/>
                </div>
              </div>
             <v-data-table
              :headers= 'headers'
              :items="LeaveRecords"
             >
                <template v-slot:item.actions="{ item }" >
                    <div class="d-flex">
                    <Button :btnType="'Cancel'" :label="'Delete'" @onClick="openDeleteModal(item)" />
                    </div>
              </template>

             </v-data-table>

              <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="deleteJobtitle" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { requestService } from '@/services'
import moment from 'moment'
// import { UPDATE_JOBTITLE, FETCH_EMPLOYEES, DELETE_JOBTITLE } from '@/store/action-type'
export default {
  components: {
    Button,
    Dialog,
    TextField
  },
  data () {
    return {
      search: '',
      formData: {
        name: '',
        id: ''
      },
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      LeaveRecords: [],
      headers: [
        {
          text: 'Personnel Name',
          value: 'personnelName'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'Resumption Date',
          value: 'endDate'
        },
        {
          text: 'No. of Days',
          value: 'days'
        },
        {
          text: 'Request Date',
          value: 'date'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ]
    }
  },
  computed: {
    // employees () {
    //   return this.$store.getters.employees
    // }
  },
  methods: {
    addNew () {
      // this.$router.push('/medical/add')
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    openDeleteModal (item) {
      this.$refs.deleteModal.toggleModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    deleteJobtitle () {
      // this.$store.dispatch(DELETE_JOBTITLE, this.formData).then((result) => {
      //   this.showAlertMessage('Jobtitle deleted successfully', 'success')
      //   // TODO remove this console.log
      // }).catch(error => {
      //   console.log(error.message)
      //   // TODO please put an alert component here
      // })
    },
    cancel (modal) {
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    }

  },
  mounted () {
    requestService.getPendingLeave().then(result => {
      const data = result.data.items
      const records = []
      data.forEach(item => {
        const request = item.request
        const employee = item.request.employee
        const requestType = item.request.requestType
        records.push({
          personnelName: `${employee.firstName}, ${employee.lastName}`,
          name: requestType.name,
          amount: item.amount,
          startDate: moment(request.startDate).format('DD-MMM-yyyy'),
          endDate: moment(request.resumptionDate).format('DD-MMM-yyyy'),
          days: request.leaveDays,
          date: moment(request.dateAdded).format('DD-MMM-yyyy'),
          status: 'pending'
        })
      })

      this.LeaveRecords = records
    })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
